/*
    DEMO STYLE
*/
body {
  font-family: "Montserrat";
  background: #f8f6f6;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}
.header-btns #header-btn {
  position: absolute;
  right: 3px;
  top: 7px;
  height: 30px;
}

#header-btn {
  margin-top: 3px;
  width: 30px;
  height: 40px;
  display: none;
  cursor: pointer;
}
#header-btn span {
  height: 2px;
  margin: 0 auto;
  display: block;
  margin-bottom: 5px;
}

.wrapper {
  display: flex;
  width: 100%;
}

.header-btns {
  position: relative;
}

.account-status {
  color: #fff;
  padding-left: 20px;
}

.comp-heading {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -50px;
  width: 100%;
  margin-left: -6px;
  z-index: 999;
}
.comp-heading label {
  padding-left: 20px;
  font-size: 15px;
}
.comp-heading hr {
  margin-top: 2px;
  margin-left: -20px !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: calc(100% - 215px);
  transition: all 0.3s;
  position: absolute;
  top: 110px;
  right: 0;
}

#content.active {
  width: 100%;
}

/* ---------------------------------------------------
    body
----------------------------------------------------- */
.main-body {
  padding-left: 15px;
  margin-right: 10px;
}

/* --------------------------------------
                Supplier 
---------------------------------------*/
.copy-address {
  font-size: 0.9em !important;
}

/* --------------------------------------
                common 
---------------------------------------*/
.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.no-data {
  width: 100%;
  margin-top: 10px;
  text-align: center;
}
#footer {
  width: 100%;
  text-align: center;
  padding: 15px;
  background-color: #fff;
}
.comp-heading .col-sm-6:last-child {
  text-align: right;
}
.comp-heading .col-sm-6:first-child {
  text-align: left;
}
table .input-label {
  text-align: left !important;
}
.table-view table thead tr th {
  font-size: 0.8em !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.padding-1 {
  padding-left: 2px;
  padding-right: 2px;
}
.padding-1-right {
  padding-right: 2px;
  padding-left: 15px;
}
.table-view thead th {
  border-right: 2px solid #eee;
}
.searchable-dropdown .css-14jk2my-container {
  margin-top: -5px !important;
}

textarea {
  font-size: 0.8em;
  width: 100%;
  height: 150px;
  padding: 6px 12px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}
.hover-dropdown {
  position: relative;
  display: inline-block;
}

.hover-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  top: 60px;
  /* right: 0; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 0;
  z-index: 10001;
}
.hover-dropdown-data div {
  padding: 5px 8px;
  cursor: pointer;
}
.hover-dropdown-data div:hover {
  background: #eee;
}
.no-drop {
  cursor: no-drop !important;
}
.form-control {
  height: 38px !important;
}
.inline-form1 .field1 {
  display: grid;
  grid-template-columns: 1fr;
}
.inline-form .field {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-gap: 10px; */
}
#settings-field input[type="checkbox"],
.inline-form input[type="checkbox"] {
  margin-top: 15px !important;
}
.inline-form .switch {
  margin-top: 12px;
}
.inline-form label,
.inline-form a {
  padding-top: 15px !important;
}
.inline-form1 label,
.inline-form1 a {
  text-align: left;
  padding-left: 6px;
}
.inline-form label,
.inline-form a {
  text-align: right;
  padding: 6px;
}
.inline-form a,
.inline-form1 a {
  color: #2684e9;
  font-size: 0.8em;
  cursor: pointer;
}
.inline-form a:hover,
.inline-form1 a:hover {
  text-decoration: underline;
}
.inline-form .css-2b097c-container {
  /* margin-top: 2px !important; */
  width: 100%;
}
.inline-form select,
.inline-form input,
.inline-form textarea,
.inline-form1 select,
.inline-form1 .switch,
.inline-form1 input,
.inline-form1 textarea,
.inline-form .container-d,
.inline-form .css-2b097c-container {
  margin-top: 2px !important;
}
.inline-form label,
.inline-form1 label {
  font-weight: normal;
  font-size: 0.8em;
}
#table-action-dropdown a {
  display: block;
}
#table-action-dropdown a {
  display: block;
  padding: 2px 5px;
}
#table-action-dropdown a:hover {
  background: #f8f9fa;
  text-decoration: none;
}
#dashboard-row {
  margin-left: 0px;
  margin-right: 0px;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  /* padding: 10px; */
}
.grid-container1 .grid-item1:nth-child(1),
.grid-container3 .grid-item:nth-child(1) {
  margin-right: 5px;
}
.grid-item {
  padding: 10px;
}
.grid-container1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* padding: 10px; */
}
.grid-container3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* padding: 10px; */
}
.dropdown-menu {
  background: #f8f6f6;
  /* background: #383d41; */
}
.dropdown-menu li a,
.dropdown-menu a span,
.dropdown-menu a i {
  /* color: #fff; */
  color: #000000;
}
.dropdown-menu li a:hover {
  color: #000000;
  background: #e5e6e4;
}
.quickLinks-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.quickLinks-dropdown-content {
  transition: all 0.3s;
  transform: translate(-50%, 0) !important;
  height: 0;
  border-radius: 5px;
  background-color: #353737;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10000;
}
.quickLinks-dropdown i {
  transition: all 0.2s;
  font-size: 1.4em;
}
.quickLinks-dropdown i:hover {
  transform: scale(1.3);
}
.quickLinks-dropdown-content a {
  cursor: pointer;
  color: #fff;
  font-weight: normal;
  padding: 5px 8px;
  text-decoration: none;
  display: block;
  font-size: 0.8em;
  border-radius: 5px;
}

.quickLinks-dropdown-content a:hover {
  background-color: #1f2427;
}
.quickLinks-dropdown:hover .quickLinks-dropdown-content {
  height: auto;
}
.available-manus hr {
  margin-top: 2px;
  margin-right: -15px;
  margin-bottom: 10px;
}
.available-manus .manu-items .row .col-xs-6 {
  margin-bottom: 5px;
}

.available-manus .manu-items .heading {
  margin-top: 20px;
}

.available-manus .manu-items .item {
  font-size: 0.8em;
  padding: 5px;
  border-radius: 5px;
  border: 2px dotted #ddd;
  background-color: #f4f7f9;
}

.available-manus .manu-items .item i {
  font-size: 1.3em;
  width: 15px;
}
.manus {
  border-right: 1px solid #ddd;
  margin-top: -20px;
  padding-top: 20px;
}
.manu {
  position: fixed;
  margin-left: 7%;
  margin-top: 3%;
}

.quicklinks-content {
  padding: 20px;
  background-color: #fff;
  border-bottom-right-radius: 5px;
  border: 1px solid #e11e39;
  border-right: 1px solid #e11e39;
  border-left: 1px solid #e11e39;
  z-index: 10000;
}
.quicklinks-dropdown-content {
  padding: 20px;
  display: none;
  position: fixed;
  background-color: #fff;
  min-width: 400px !important;
  /* left: 38%; */
  border: 5px;
  border-radius: 5px;
  border: 1px solid #e11e39;
  z-index: 10000;
}
.quicklinks-dropdown-content .row,
.quicklinks-content .row {
  width: 400px !important;
}

.quicklinks-dropdown-content .row a,
.quicklinks-content .row a {
  color: #000000;
  font-weight: normal;
  padding: 5px;
  border-radius: 5px;
  text-decoration: none;
  display: block;
  text-align: left !important;
  font-size: 0.8em;
  width: 110%;
}

.quicklinks-dropdown-content .row a:hover,
.quicklinks-content .row a:hover {
  text-decoration: none;
  cursor: pointer;
  background-color: #333;
  color: #fff;
}
.quicklinks-dropdown-content .dropdown-divider,
.quicklinks-content .dropdown-divider,
.selected-quicklinks-content .dropdown-divider {
  margin-top: 10px;
  margin-bottom: 10px;
}
.quicklinks-dropdown-content .row a:hover .quicklinks-dropdown-content a i {
  color: #e11e39 !important;
}
.quicklinks-dropdown-content .row a i,
.quicklinks-content .row a i {
  font-size: 1.3em;
  width: 25px;
}
.quicklinks-dropdown:hover .quicklinks-dropdown-content,
.quicklinks-content {
  display: block;
}
.quicklinks-dropdown-content .link-to-quicklinks {
  font-size: 0.9em;
  color: #2684e9;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
}
.quicklinks-dropdown-content .link-to-quicklinks:hover {
  text-decoration: underline;
}
.quicklinks-dropdown-content .link-to-quicklinks i {
  font-size: 0.9em;
}

.selected-quicklinks {
  width: 450px;
}

.selected-quicklinks .selected-quicklinks-content {
  border-radius: 5px;
  padding: 25px 0 25px 25px;
  border: 1px solid #e11e39;
}

.selected-quicklinks .selected-quicklinks-content .item i {
  width: 15px;
}
.selected-quicklinks .selected-quicklinks-content .item {
  font-size: 0.8em;
  padding: 5px;
  border-radius: 5px;
  border: 2px dotted #ddd;
  background-color: #f4f7f9;
  margin-bottom: 10px;
}
.quickLinks-dropdown {
  float: right;
  text-align: center;
}
.quickLinks-dropdown .fa-check {
  color: green;
}
.quickLinks-dropdown .fa-times {
  color: red;
}
.dataTables_info {
  opacity: 0.9;
  font-size: 0.8em !important;
}
.dataTables_paginate {
  text-align: center !important;
}
.pagination li a {
  padding: 2px 8px 2px 8px !important;
}
.pagination li a:hover {
  text-decoration: none;
}
.form-control {
  height: 30px;
}
.table-view a {
  color: #0062cc;
}
datalist {
  display: none;
  height: 120px;
  overflow: auto;
  left: 0;
  border: 1px solid black;
}
#baseCurrecny .css-yk16xz-control {
  background-color: #eee !important;
  pointer-events: none;
}
.css-2b097c-container {
  margin-top: -5px !important;
}
/* .css-yk16xz-control {
	height: 30px !important;
	min-height: 30px !important;
}
.css-1hwfws3 {
	padding: 5px !important;
	margin-top: -5px !important;
}
.css-1hb7zxy-IndicatorsContainer {
	height: 30px !important;
}
.css-1pahdxg-control {
	height: 30px !important;
	min-height: 30px !important;
}
.css-1pahdxg-control:hover {
	height: 30px !important;
	min-height: 30px !important;
} */
/* .form-inline input,
.form-inline select {
	height: 30px !important;
} */
#run-report {
  text-align: center;
}

#run-report h5 {
  opacity: 0.7 !important;
}

.custom-btn {
  border: 2px solid #65a523;
  color: #65a523;
  width: 250px;
  padding: 8px 20px;
  border-radius: 5px;
  text-align: center;
  background: #fff;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}

.custom-btn:hover {
  background: #65a523;
  color: #fff;
}

.pagination li a {
  margin-right: 0 !important;
}
.pagination li.active {
  color: #fff !important;
}
.table-responsive {
  padding-top: 10px !important;
  border: none !important;
}
.padding-right {
  margin-right: -8px !important;
}
#form-inline-input a {
  color: #0062cc !important;
  font-size: 0.8em !important;
  margin-right: 12px !important;
}
#form-inline-input label {
  font-size: 0.8em !important;
  margin-right: 12px !important;
}
.form-inline a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.dt-button-collection {
  margin-top: 10px !important;
}
#view-product-table_length {
  /* margin-left: 45%; */
}
#view-product-table_length .form-control {
  width: 80px !important;
}
.dt-buttons {
  margin-bottom: -28px;
  /* margin-left: 25%; */
}
.dt-button {
  /* padding: 16px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  /* margin: 4px 2px; */
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
  padding: 5px 20px 5px 20px;
  background-color: white;
  color: black;
  border: 1px solid #555555;
  border-radius: 5px;
}
.dt-button:hover {
  background-color: #555555;
  color: white;
}
.canvasjs-chart-credit {
  opacity: 0;
  pointer-events: none;
}
.dropdown-menu li a {
  font-size: 0.9em;
}
.fa-trash-o:hover {
  color: #e11e39;
}
.fa-pencil-square-o:hover {
  color: #0062cc !important;
}
.btn {
  padding-left: 20px;
  padding-right: 20px;
}
.form-group {
  margin-right: 10px;
}
table thead th {
  background: #f8f6f6;
  /* border-right: 2px solid #fff; */
}
table tbody {
  background: #fff;
  /* border-right: 2px solid #fff; */
}
table thead th:hover {
  opacity: 0.8;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* background: #f8f6f6; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1c4e80;
  /* background: #f8f6f6; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* background: #f8f6f6; */
}
.panel .panel-heading {
  cursor: pointer;
  background: #d5d8db !important;
  height: 25px;
  /* margin-right: 10px; */
  /* box-shadow: 12px 15px 20px 0px rgba(46, 61, 73, 0.15); */
}
.panel .panel-heading .panel-title {
  font-size: 0.9em;
  font-weight: bolder;
  margin-top: -5px;
}
#comp-tab li a {
  font-size: 0.8em;
  font-weight: bold;
  color: #0c81a6;
}
#comp-tab li {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
#comp-tab li:hover {
  background-color: #eee;
}
.tab-content {
  padding-top: 10px;
}
#pagination div {
  display: inline !important;
}
#pagination div ul {
  display: flex;
  list-style-type: none;
  justify-content: center;
}
#pagination div ul li:first-child {
  border-left: 1px solid #aaaeb3;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
#pagination div ul li:last-child {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
#pagination div ul li {
  /* border-radius: 2px; */
  border-right: 1px solid #aaaeb3;
  border-top: 1px solid #aaaeb3;
  border-bottom: 1px solid #aaaeb3;
  padding: 2px 10px 2px 10px !important;
}
.pagination .disabled,
.pagination .disabled a {
  opacity: 0.7;
  cursor: no-drop !important;
}
.pagination .active {
  background: #2684e9 !important;
  color: #fff;
}
#settings-field .css-14jk2my-container {
  margin-top: -5px !important;
}
.padding-0 {
  padding-right: 0;
  padding-left: 0;
}
.green {
  color: green;
}
.red {
  color: red;
}
.billing-a {
  color: red !important;
  cursor: pointer;
  text-decoration: none;
}
.billing-a:hover {
  text-decoration: underline;
}
.panel-inputs .form-control {
  width: 180px !important;
}
/* --------------------------------------
                purchases 
---------------------------------------*/
.stat-label {
  font-size: 0.7em;
}
.stat-label,
.stat-value {
  opacity: 0.8;
  margin-right: 5px;
}
.stat-div {
  text-align: right;
  border-right: 15px solid #c6ced6;
}
.border-bottom {
  border-bottom: 1px solid #c6ced6;
  padding-top: 5px;
  padding-bottom: 5px;
}
.receipt-stat-label {
  opacity: 0.8;
  font-size: 0.8em;
}
.receipt-stat-value {
  opacity: 0.8;
}
/* --------------------------------------
                product 
---------------------------------------*/
#details-parent-dev {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
#cloneModal .modal-body p {
  font-size: 0.9em;
}
#cloneModal .modal-header h4 {
  font-size: 1.1em;
  font-weight: bolder;
}
#cloneModal .modal-dialog {
  width: 430px;
}
.modal-body strong {
  text-transform: uppercase;
}
.table-borderless td,
.table-borderless th {
  border: none !important;
}
.fa {
  transition: all 0.2s;
}
.table-input {
  text-align: left;
}
.table-input i {
  /* margin-top: 3px; */
  width: 5px;
  font-size: 1.2em;
}
.table-input .fa-times {
  color: red;
}
.table-input .fa-check {
  color: green;
}
.input-label {
  font-size: 0.8em !important;
  font-weight: 100;
  vertical-align: middle !important;
  text-align: right;
  margin-top: 5px;
  margin-right: 10px;
  cursor: pointer;
}
/* .table-input input {
	width: 60%;
} */
.table-input textarea {
  /* width: 60%; */
  height: 100px;
}
.input-label a {
  font-size: 1em !important;
  margin-top: -10px !important;
  color: #0c81a6;
}

table .input-label a {
  font-size: 1.2em !important;
}
.table-view tbody td {
  font-size: 0.9em !important;
}
.input-label a:hover,
.input-label a:hover {
  text-decoration: underline;
}
.form-control {
  font-size: 0.8em;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  /* margin-bottom: -10px !important; */
}
.form-control:hover {
  /* box-shadow: 0 2px 1px 0 rgba(97, 95, 95, 0.19), 0 2px 6px 0 rgba(97, 95, 95, 0.19) !important ; */
}
.btn:hover {
  box-shadow: 0 2px 1px 0 rgba(97, 95, 95, 0.19) !important ;
}
.table-view tbody tr:hover {
  /* box-shadow: 0 2px 1px 0 rgba(97, 95, 95, 0.19), 0 2px 6px 0 rgba(97, 95, 95, 0.19) !important ; */
}
.table-view tbody tr td .form-control {
  margin-top: 1px !important;
}
.my-hr {
  /* margin-left: -45% !important;
  margin-right: -117% !important; */
}
.product-section-heading {
  margin-top: 20px;
  margin-bottom: -20px;
  margin-left: 5% !important;
}
.my-hr1 {
  /* margin-left: -7% !important; */
  /* margin-right: -100% !important; */
}
.product-section-heading1 {
  margin-top: 20px;
}
.notes-textarea textarea {
  width: 150%;
  height: 200px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 20px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #65a523;
}

input:focus + .slider {
  box-shadow: 0 0 1px #65a523;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
table tbody tr td {
  padding-bottom: 2px !important;
}

.info {
  padding: 10px 20px 15px 20px;
  border: 2px solid #0c81a6;
  background-color: #e7f8fd;
  border-radius: 5px;
  margin-right: 10px;
}
.info label {
  color: #0c81a6;
  font-size: 1em;
  font-weight: bold;
}
.info i {
  font-size: 1.5em;
  margin-right: 22px;
}
.info span {
  margin-top: -4px;
  display: block;
  font-size: 0.8em;
  color: #000000;
  margin-left: 40px;
}
.info a {
  display: block;
  cursor: pointer;
  font-size: 1em;
  margin-top: 3px;
  color: #0062cc !important;
}
.info a:hover {
  text-decoration: underline;
}

.stepsInfo {
  padding: 10px 20px 10px 0;
  border-radius: 5px;
  margin-left: -5px;
}
.stepsInfo .label {
  color: #505b64;
  font-size: 1em;
  font-weight: bold;
}
.stepsInfo .label .info-icon {
  color: #fff;
  font-weight: bold;
  margin-right: 18px;
  padding: 4px 8px 4px 8px;
  background: #505b64;
  border-radius: 50%;
}

.stepsInfo .span {
  display: block;
  font-size: 0.8em;
  color: #000000;
  margin-left: 50px;
  margin-top: 5px;
}
.stepsInfo a {
  display: block;
  cursor: pointer;
  font-size: 1em;
  margin-top: 15px;
  color: #0062cc !important;
}
.stepsInfo a:hover {
  text-decoration: underline;
}

.table-view {
  width: 99.3%;
}
.table-view .fa-cog {
  color: #888 !important;
  /* min-height: 400px; */
}
.table-view small {
  margin-left: 40%;
  font-style: italic;
  opacity: 0.7;
  font-size: 0.7em;
}

.table-view thead tr th {
  font-size: 1em;
}
.table-view tbody tr td {
  font-size: 1em;
  padding: 10px !important;
}
#inventory-table th:last-child,
#inventory-table td:last-child {
  text-align: center;
}
.inventory-table-input .form-control {
  height: 27px !important;
  /* margin-bottom: 4px !important; */
  margin-top: -7px !important;
  font-size: 1em;
}
.inventory-table-input:hover input {
  cursor: pointer;
}
.inventory-hr {
  margin-top: -10px !important;
  color: #c6ced6;
  background-color: #c6ced6;
  height: 1px;
}
#purchase .form-control,
#sale .form-control,
#view-product .form-control,
#change-code .form-control,
#customer .form-control,
#production .form-control {
  margin-top: -5px !important;
  height: 38px !important;
  width: 85%;
}
#stock-adjustments .form-control,
#warehouse-transfers .form-control,
#stock-count .form-control,
#settings-field .form-control,
#role-permission .form-control {
  margin-top: -5px !important;
  height: 38px !important;
}

#purchase div,
#sale div,
#view-product div,
#customer div,
#production div {
  margin-right: -50px !important;
}
.purchase-hr {
  margin-top: 5px;
}
#add-supplier-btn button,
#add-customer-btn button,
#add-production-btn button {
  height: 38px;
  /* opacity: 0.8; */
  /* width: 60px; */
  margin-left: -15px;
  margin-top: 15px !important;
}
#add-supplier-btn label,
#add-customer-btn label,
#add-production-btn label {
  opacity: 0;
}
#add-btn label {
  opacity: 0;
}
#add-btn button {
  height: 38px;
  margin-top: -4px;
}
.markup-btn {
  width: 100%;
  text-align: center !important;
  margin-bottom: -20px;
}
#sale-markup-btn {
  margin-right: 10px !important;
  background: #eceeef !important;
  margin-bottom: 10px !important;
}
#sales-table td,
#sales-table th {
  text-align: right;
}
#sales-table thead th:nth-child(2),
#sales-table tbody td:nth-child(2),
#sales-table thead th:nth-child(3),
#sales-table tbody td:nth-child(3) {
  width: 20%;
  /* table-layout: fixed; */
}
#sales-table td:first-child,
#sales-table th:first-child {
  text-align: left;
  width: 20%;
}
#sales-table th:last-child,
#sales-table td:last-child {
  text-align: center;
}

#suppliers-table th:last-child,
#suppliers-table td:last-child,
#view-supplier-retutn-table th:last-child,
#view-supplier-retutn-table td:last-child,
#view-purchases-table th:last-child,
#view-purchases-table td:last-child {
  text-align: center;
  width: 5% !important;
}
#quotes-table th:first-child,
#quotes-table td:first-child {
  text-align: center;
}
#hide-td {
  display: none;
}
#References tr th:first-child {
  width: 20%;
}
#References tr th:nth-of-type(2) {
  width: 60%;
}
#References tr th:last-child {
  width: 20%;
  text-align: right;
}
#References tr td:last-child {
  width: 20%;
}
.row {
  width: 100% !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.row div #customer {
  margin-bottom: 10px !important;
}
#cost-table tr th:nth-of-type(6),
#cost-table tr th:nth-of-type(7),
#cost-table tr th:nth-of-type(8),
#cost-table tr th:nth-of-type(9),
#cost-table tr th:nth-of-type(10),
#cost-table tr td:nth-of-type(6),
#cost-table tr td:nth-of-type(7),
#cost-table tr td:nth-of-type(8),
#cost-table tr td:nth-of-type(9),
#cost-table tr td:nth-of-type(10) {
  text-align: right;
}
.attachments-upload {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.attachments-upload button {
  font-size: 1.2em;
  font-weight: 500;
}
.attachments-upload p {
  font-size: 0.7em;
}
#uploadFileLink {
  margin-left: 2px;
  margin-right: 2px;
  color: #0c81a6;
}
#uploadFileLink:hover {
  text-decoration: underline;
}
#uploadFileSizeLimit {
  margin-left: 2px;
}
.attachment-message {
  width: 100%;
  text-align: center;
}
.attachment-message span {
  opacity: 0.6;
  font-size: 0.8em;
}
.file-drop-area {
  cursor: pointer;
  width: 99%;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  background: #f0f3f4;
  border-radius: 5px;
  border: 2px dashed #e1e7e9;
  transition: all 0.3s;
}
.file-drop-area:hover {
  border: 2px solid #aaaeb3;
  background: #fff;
}
.file-drop-area input {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
}
.file-drop-area p {
  width: 100%;
  text-align: center;
  color: #000000;
  opacity: 0.5;
}
.file-drop-area i {
  font-size: 1.5em;
  opacity: 0.5;
}

.my-dropdown {
  /* position: relative; */
  display: inline-block;
}
.fa-cog.dropdown-toggle {
  cursor: pointer;
}
.my-dropdown-content {
  display: none;
  position: absolute;
  border-radius: 5px;
  /* top: 10px; */
  right: 70px;
  text-align: left;
  background-color: #f8f6f6;
  /* background-color: #353737; */
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10000;
}

.my-dropdown-content a {
  /* color: #fff; */
  color: #000000;
  font-weight: normal;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  font-size: 1em;
  /* border-radius: 5px; */
}

.my-dropdown-content a:hover {
  text-decoration: none;
  background-color: #e5e6e4;
  /* background-color: #1f2427; */
}
.my-dropdown:hover .my-dropdown-content {
  display: block;
}

/* ---------------------------------------------------
    View Product
----------------------------------------------------- */
#view-product-table tr th:nth-of-type(6),
/* #view-product-table tr th:nth-of-type(7), */
#view-product-table tr th:nth-of-type(8),
#view-product-table tr th:nth-of-type(9),
#view-product-table tr th:nth-of-type(10),
#view-product-table tr td:nth-of-type(6),
#view-product-table tr td:nth-of-type(7),
#view-product-table tr td:nth-of-type(8),
#view-product-table tr td:nth-of-type(9),
#view-product-table tr td:nth-of-type(10) {
  text-align: right;
}
#view-product-table tr th:last-child,
#view-customers-table tr th:last-child {
  width: 15px;
  text-align: center;
}
#view-product-table tr td:last-child,
#view-customers-table tr td:last-child {
  /* opacity: 0.5; */
  text-align: center;
}
#view-product-table tr th:nth-child(9),
/* #view-product-table tr th:nth-child(7), */
#view-product-table tr th:nth-child(6) {
  width: 20px;
}
#view-product-table tr th:nth-child(8) {
  width: 120px;
}
.comp-heading button {
  margin-right: 2px;
  /* margin-top: -8px; */
}
.btn-group:first-child {
  margin-right: 5px !important;
  /* width: 130px; */
}
.product-pricing,
.customer-pricing {
  text-align: center;
}
.product-pricing p,
.customer-pricing p {
  font-size: 0.8em;
}
/* ---------------------------------------------------
    Dashboard
----------------------------------------------------- */
.dashboard-hr {
  margin-top: 1px !important;
  background-color: #c6ced6;
  height: 1px;
  opacity: 0.6;
}
.padding-0 {
  padding-right: 5px;
}
#last-hr {
  margin-right: -50px;
}
.dashboard strong {
  opacity: 0.8;
  font-size: 0.9em;
}

.dashboard-chart-info {
  position: relative;
  height: 130px;
  border-radius: 10px;
  text-align: center;
  background: #fff;
  box-shadow: 0 0 10px 3px rgba(46, 61, 73, 0.1);
}
.dashboard-chart-info .glyphicon {
  position: absolute;
  top: 25px;
  right: 30px;
  color: #e11e39;
  font-size: 3em;
}
.dashboard-chart-info .percentage {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 2em;
}
.dashboard-chart-info .dashboard-label {
  position: absolute;
  left: 20px;
  top: 70px;
  font-size: 1em;
  font: 200 !important;
}
.dashboard-chart-info .dashboard-number {
  position: absolute;
  top: 100px;
  left: 20px;
  font-size: 0.8em;
}
.dashboard #transaction,
.dashboard #stock,
.dashboard #kpis,
.dashboard #batch-expiry {
  height: 325px;
}
.wrimagecard {
  margin-top: 0;
  margin-bottom: 1.5rem;
  text-align: left;
  position: relative;
  background: #fff;
  box-shadow: 12px 15px 20px 0px rgba(46, 61, 73, 0.15);
  border-radius: 10px;
  transition: all 0.3s ease;
}
.wrimagecard .fa {
  position: relative;
  font-size: 50px;
}
.wrimagecard .glyphicon {
  position: relative;
  font-size: 45px;
}
.wrimagecard-topimage_header {
  padding: 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
a.wrimagecard:hover,
.wrimagecard-topimage:hover {
  box-shadow: 2px 4px 8px 0px rgba(46, 61, 73, 0.2);
}
.wrimagecard-topimage_title {
  padding: 20px 24px;
  height: 80px;
  padding-bottom: 0.75rem;
  position: relative;
}
.wrimagecard-topimage a {
  width: 100%;
  height: 100%;
  display: block;
  border-bottom: none;
  text-decoration: none;
  color: #525c65;
  transition: color 0.3s ease;
}

/*---------------------------------------------------- 
				Inventory change codes
----------------------------------------------------*/
#change-code-table thead th:first-child {
  width: 10%;
}
#change-code-table thead th:nth-child(2),
#change-code-table thead th:nth-child(3) {
  width: 20%;
}
#change-code-table tbody td:last-child,
#change-code-table thead th:last-child {
  text-align: center;
  cursor: pointer;
}
#change-code-table tbody td:last-child {
  /* color: red; */
}
#change-code-search-icon {
  padding-top: 17px;
  margin-left: -30px !important;
  opacity: 0.7;
  cursor: pointer;
  font-size: 1.4em !important;
}
#change-code-search-icon:hover {
  opacity: 1;
}
#Batch .row {
  padding-left: 10px;
}
.info-number {
  color: #fff;
  font-weight: bold;
  padding: 4px 8px 4px 8px;
  background: #505b64;
  border-radius: 50%;
}
.info-heading {
  opacity: 0.7;
  font-weight: bold;
  font-size: 0.9em;
}
#Batch p {
  font-size: 0.7em;
  opacity: 0.8;
}
.info-link {
  color: #0062cc;
  font-size: 0.8em !important;
}
.info-link:hover {
  color: #0062cc;
  text-decoration: underline;
}
#info-btn {
  width: 30%;
  padding-left: 10px;
  height: 30px !important;
  /* padding-left: 30px;
	padding-right: 30px; */
}
#info-card {
  /* height: 130px; */
  /* padding: 30px;
	border-radius: 5px;*/
  margin-bottom: 10px;
  /* box-shadow: 0 2px 1px 0 rgba(97, 95, 95, 0.19), 0 2px 6px 0 rgba(97, 95, 95, 0.19) !important ; */
}

/*---------------------------------------------------- 
				Product Markup Price Update
----------------------------------------------------*/
#batch-table th:first-child,
#batch-table th:nth-child(3),
#batch-table th:nth-child(4) {
  width: 10% !important;
}
#batch-table th:nth-child(2) {
  width: 15% !important;
}
#batch-table th:nth-child(5),
#batch-table th:nth-child(6),
#batch-table th:nth-child(7),
#batch-table th:nth-child(8),
#batch-table th:nth-child(9),
#batch-table th:nth-child(10),
#batch-table th:nth-child(11),
#batch-table th:nth-child(12),
#batch-table th:nth-child(13),
#batch-table th:nth-child(14),
#batch-table td:nth-child(5),
#batch-table td:nth-child(6),
#batch-table td:nth-child(7),
#batch-table td:nth-child(8),
#batch-table td:nth-child(9),
#batch-table td:nth-child(10),
#batch-table td:nth-child(11),
#batch-table td:nth-child(12),
#batch-table td:nth-child(13),
#batch-table td:nth-child(14) {
  text-align: right !important;
}

/* ---------------------------------------------------
					Stock Adjustments
----------------------------------------------------- */
#stock-adjustments-table th:nth-child(6),
#stock-adjustments-table th:nth-child(7),
#stock-adjustments-table th:nth-child(8),
#stock-adjustments-table td:nth-child(6),
#stock-adjustments-table td:nth-child(7),
#stock-adjustments-table td:nth-child(8) {
  text-align: right;
}

#stock-adjustments-table td:nth-child(9) {
  text-align: center;
}
.stock-adjustment-status {
  border-radius: 20px;
  padding: 1px 5px 1px 5px;
  text-transform: uppercase;
  width: 60px !important;
}

/* ---------------------------------------------------
					Warehouse Transfers
----------------------------------------------------- */
#stock-price-count {
  padding-top: 10px;
  height: 80px;
  border-right: 20px solid #c6ced6;
}
#stock-price-count div {
  float: right;
}
#stock-price-count .transfers-label {
  opacity: 0.6;
}
#stock-price-count .transfers-count {
  font-size: 2em;
}

/* ---------------------------------------------------
				Import Stock Revaluation
----------------------------------------------------- */
.stock-revaluation-steps {
  color: #363838;
  font-size: 18px;
  font-weight: 700;
  opacity: 0.8;
  padding: 10px 0 10px 5px;
}

/*---------------------------------------------------- 
				Registration
----------------------------------------------------*/
.register {
  background: -webkit-linear-gradient(left, #0d0b27, #737575);
  margin-top: 3%;
  padding: 3%;
}
.register-left {
  text-align: center;
  color: #fff;
}
.register-left h2 {
  font-size: 3em;
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.register-left a {
  font-style: italic;
  color: #2684e9 !important;
  cursor: pointer;
  text-decoration: underline !important;
}

.register-left input {
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  width: 60%;
  background: #f8f9fa;
  font-weight: bold;
  color: #383d41;
  margin-top: 10%;
  margin-bottom: 3%;
  cursor: pointer;
}
#home,
#profile {
  background: none !important;
}
.register-right {
  background: #f8f9fa;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
}
.register-left img {
  margin-top: 15%;
  margin-bottom: 5%;
  width: 50%;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
.register-left p {
  font-weight: lighter;
  padding: 12%;
  margin-top: -9%;
}
.register .register-form {
  padding: 10%;
  margin-top: 10%;
}
.register .register-form:first-child {
  margin-bottom: 10px !important;
}
.register .register-form .form-control {
  font-size: 1em !important;
}
.btnRegister {
  float: right;
  /* margin-top: 36%; */
  border: none;
  border-radius: 0.2rem;
  padding: 2%;
  background: #1c4e80;
  color: #fff;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
}
.btnLogin {
  float: right;
  margin-top: 3%;
  border: none;
  border-radius: 0.2rem;
  padding: 2%;
  background: #1c4e80;
  color: #fff;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
}
.register .nav-tabs {
  margin-top: 3%;
  border: none;
  background: #0062cc;
  border-radius: 1.5rem;
  width: 28%;
  float: right;
}
.register .nav-tabs .nav-link {
  padding: 2%;
  height: 34px;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover {
  border: none;
}
.register .nav-tabs .nav-link.active {
  width: 100px;
  color: #0062cc;
  border: 2px solid #0062cc;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.register-heading {
  text-align: center;
  margin-top: 8%;
  margin-bottom: -15%;
  color: #495057;
}

/* ---------------------------------------------------
				Setting/security/users
----------------------------------------------------- */
.account-owner {
  margin-top: 30px;
  opacity: 0.8;
}

#users-table thead tr th:first-child {
  width: 30% !important;
}
#users-table thead tr th:nth-child(2) {
  width: 30% !important;
}
#users-table thead tr th:nth-child(4),
#users-table thead tr th:nth-child(5),
#users-table thead tr th:nth-child(6),
#users-table tbody tr td:nth-child(4),
#users-table tbody tr td:nth-child(5),
#users-table tbody tr td:nth-child(6) {
  text-align: center;
}
#users-table i {
  font-size: 1.3em !important;
}

.user-action-icons i {
  margin-right: 10px;
  cursor: pointer;
}

.user-action-icons .fa-trash-o:hover {
  color: red;
}

.user-action-icons .fa-pencil-square-o:hover {
  color: royalblue;
}

/* ---------------------------------------------------
				Setting/security/roles
----------------------------------------------------- */
#system-add-btn {
  margin-top: 22px;
}
#system-add-btn button {
  padding: 8px 20px 8px 20px;
}
#roles-table thead th:first-child,
#delivery-methods-table thead th:first-child {
  width: 90% !important;
}
#delivery-methods-table thead th:last-child,
#roles-table thead td:first-child {
  text-align: center;
}
#roles-table thead th:nth-child(2),
#roles-table tbody td:nth-child(2) {
  text-align: center;
}
#roles-table tbody tr td i {
  font-size: 1em;
  cursor: pointer;
}
#roles-table tbody tr td i:hover {
  color: red;
}
#sell-price-tier button {
  float: right;
  width: 100px;
  margin-top: 5px;
}
/* ---------------------------------------------------
				Setting/security/roles Permission
----------------------------------------------------- */
.role-permissions .col-xs-1 {
  cursor: pointer;
  text-align: center !important;
}
.role-permissions .permission-more-icon {
  transition: 0.3s all;
}

.role-permissions .col-xs-1[aria-expanded="true"] .permission-more-icon {
  transform: rotateZ(90deg);
}
.role-permissions .row {
  padding: 3px;
  border-bottom: 1px solid #eee;
}
.role-permissions #heading {
  background: #ddd;
  font-size: 1em;
  font-weight: bolder;
}
#sub-permissions {
  background: #eee;
}
#sub-permissions .col-xs-5 {
  font-size: 0.8em;
}
/* -----------------------------s----------------------
    Setting/organization/billing
----------------------------------------------------- */
#billing-plans-table thead tr td {
  color: red;
}
#billing-plans-table tbody tr td span {
  font-size: 0.9em;
}

/* ---------------------------------------------------
    Setting/system/shipping company
----------------------------------------------------- */
#companies-table thead th:nth-child(2),
#companies-table tbody td:nth-child(2) {
  text-align: center;
  width: 10%;
}

/* ==================================================
   Author's custom styles
   ================================================== */
.saving {
  color: #e11e39;
}
.p-top-100 {
  padding: 100px 0;
}
.m-top-20 {
  margin-top: 20px;
}

/*=================================
Pricint fiv
==================================*/
.bs-five-area {
  /* width: 95%; */
  /* display: inline-block; */
  box-shadow: 5px 10px 30px 10px #ddd;
  overflow: hidden;
  /* position: relative;  */
  text-align: center;
}

.bs-five-area.bs-radius {
  border-radius: 30px;
}
.bs-five-area > div {
  padding: 0;
}

.bs-five-area .bs-five {
  /* position: relative; */
  /* width: 100%; */
  /* margin: auto; */
  transition: all 0.4s;
  padding: 20px 0px;
}
.bs-five-area .bs-five h6 {
  font-weight: 700;
  letter-spacing: 2px;
}
.bs-five-area .bs-five h1 {
  font-size: 64px;
  font-weight: 100;
  line-height: 72px;
}
.bs-five-area .bs-five h1 sup {
  font-size: 30px;
  top: -30px;
}
.bs-five-area .bs-five ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.bs-five-area .bs-five ul li {
  line-height: 45px;
  border-bottom: 0px;
  margin-top: 10px;
}
.bs-five-area .bs-five ul li span {
  padding: 5px;
  border-radius: 50%;
  background: #e11e39;
  color: #fff;
}

.bs-five-area .bs-five:hover {
  background: #179880;
  transition: all 0.4s;
}

.bs-five-area .bs-five.active {
  background: #179880;
  background: -webkit-linear-gradient(left, #179880, #21a476);
  background: -o-linear-gradient(right, #179880, #21a476);
  background: -moz-linear-gradient(right, #179880, #21a476);
  background: linear-gradient(to right, #179880, #21a476);
}

.bs-five-area .bs-five .btn-success {
  padding: 10px 50px;
  display: inline-block;
  border-radius: 30px;
  background-color: #179880;
  border-color: #21a476;
}

.bs-five-area .bs-five.active .btn.btn-success,
.bs-five-area .bs-five:hover.bs-five .btn.btn-success {
  background: #e0fff6;
  color: #444;
}

.bs-five-area .bs-five.active,
.bs-five-area .bs-five.active ul li,
.bs-five-area .bs-five:hover.bs-five,
.bs-five-area .bs-five:hover.bs-five ul li {
  color: #fff;
}
.bs-five-area .bs-five.active .pricing-footer a,
.bs-five-area .bs-five:hover.bs-five .pricing-footer a {
  color: #fff;
}
.pricing-footer a {
  color: #0062cc;
  font-weight: 600;
  cursor: pointer;
}
.pricing-footer a:hover {
  text-decoration: underline;
}
.bs-five {
  cursor: pointer;
}

/* ---------------------------------------------------
	Supplier
----------------------------------------------------- */

/* ---------------------------------------------------
	settings/System
----------------------------------------------------- */
.warehouse-user-access {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 5px;
}

#Configuration .col-sm-4 {
  text-align: center;
}
.config-container {
  position: relative;
  height: 100px;
  border-radius: 5px;
  border-bottom: 1px solid #0d0b27;
  margin-bottom: 10px;
}
.config-container:hover {
  background: #eee;
}

.config-container .config-card-heading {
  position: absolute;
  left: 20px;
  top: 20px;
  font-weight: 700;
}
.config-container .config-card-body {
  position: absolute;
  line-height: 1em;
  font-size: 0.8em;
  color: #999;
  top: 45px;
  left: 20px;
}
.config-container .config-card-warning,
.config-container .config-card-tip {
  position: absolute;
  top: 75px;
  line-height: 1em;
  font-size: 0.8em;
  left: 20px;
}
.config-container .config-card-warning {
  color: #e11e39;
}
.config-container .config-card-tip {
  color: #65a523;
}
.config-container .config-card-switch {
  position: absolute;
  top: 20px;
  right: 20px;
}
.config-container .config-card-switch:hover {
  cursor: pointer;
}

#setting-tables table tbody td:last-child,
#setting-tables table thead th:last-child {
  text-align: center;
  width: 10%;
  cursor: pointer;
}

#inline-input-forms .col-sm-4 b,
#inline-input-forms .col-sm-8 b,
#inline-input-forms .col-sm-3 b,
#inline-input-froms .col-sm-5 b,
#inline-input-forms .col-md-4 b,
#inline-input-forms .col-sm-2 b,
#inline-input-forms .col-xs-12 b {
  text-align: center;
}

#prefix-divs {
  margin-bottom: 10px;
}

#prefix-divs .form-control,
#inline-input-forms .form-control,
#inline-input-forms select {
  width: 200px;
}
#inline-input-forms .form-inline {
  margin-bottom: 10px;
}

#prefix-divs .col-sm-4,
#prefix-divs .col-sm-6,
#prefix-divs .col-sm-3,
#inline-input-forms .col-sm-4,
#inline-input-forms .col-sm-3,
#inline-input-froms .col-sm-5,
#inline-input-froms .col-sm-2,
#inline-input-forms .col-md-4,
#inline-input-forms .col-xs-12 {
  text-align: right;
  /* padding-right: 50px; */
}
#inline-input-forms .col-sm-8 {
  text-align: center;
  padding-right: 50px;
}
#prefix-divs input[type="checkbox"] {
  width: 14px;
  height: 14px;
}
#prefix-divs .col-sm-4 label,
#prefix-divs .col-sm-3 label,
#prefix-divs .col-sm-6 label,
#prefix-divs .col-md-3 label,
#prefix-divs .col-sm-5 label,
#inline-input-forms .col-sm-4 label,
#inline-input-forms .col-sm-8 label,
#inline-input-forms .col-sm-3 label,
#inline-input-froms .col-sm-5 label,
#inline-input-forms .col-md-4 label,
#inline-input-forms .col-sm-2 label,
#inline-input-forms .col-xs-12 label,
#taxes label {
  font-size: 0.8em !important;
  font-weight: 500;
}

#inline-input-forms .col-sm-12 label,
#inline-input-forms .col-sm-12 a {
  font-size: 0.7em !important;
  font-weight: 500;
}

#prefix-divs button {
  margin-right: 45px;
}

#sales-group-table thead th:first-child {
  width: 80% !important;
}
#sales-group-table tbody td:nth-child(2) {
  text-align: center;
}
/* #sales-group-table input[type='checkbox'] {
	width: 15px;
	height: 15px;
} */
#attribute-set-table tbody td:nth-child(3) {
  text-align: right;
}

#warehouses-table tbody td:nth-child(3),
#warehouses-table tbody td:nth-child(4),
#warehouses-table thead th:nth-child(3),
#warehouses-table thead th:nth-child(4) {
  width: 10%;
  text-align: center;
}
#warehouses-table thead th:first-child,
#warehouses-table tbody td:first-child {
  width: 15%;
}
#warehouses-table thead th:last-child {
  text-align: center;
}
.user-access-hr {
  margin-top: 5px;
  border: 1px solid #ccc;
  background: #ccc;
}
#warehouse-user-access {
  text-align: left !important;
}

#tax-table tbody td:nth-child(4),
#tax-table tbody td:nth-child(5),
#tax-table tbody td:nth-child(6),
#tax-table thead th:nth-child(4),
#tax-table thead th:nth-child(5),
#tax-table thead th:nth-child(6),
#tax-table thead th:nth-child(7) {
  width: 10%;
  text-align: center;
}
#sales-person-table thead th:last-child,
#sales-person-table tbody td:last-child,
#sales-person-table thead th:nth-child(3),
#sales-person-table tbody td:nth-child(3) {
  width: 10% !important;
  text-align: center;
}
.doc-designer {
  width: 100%;
  height: 500px;
  /* border-radius: 3px; */
  /* border: 1px solid #1f2427; */
  /* padding: 10px; */
}
#doc-checkbox {
  margin-left: -30px;
  margin-top: 2px;
}
#doc-checkbox input {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.doc-designer-btns button {
  margin-right: 2px;
}
.doc-info span {
  font-size: 0.8em;
}
.doc-info i {
  font-size: 1em;
  margin-right: 4px;
}
.template-import-heading {
  margin-top: 30px;
  margin-bottom: -20px;
}
.template-forms {
  margin-left: 22px;
}
#Preview a {
  color: #2684e9;
  font-size: 0.8em;
  cursor: pointer;
}
#Preview a:hover {
  text-decoration: underline;
}
.doc-comp {
  display: inline-block;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background: #d5d8db;
  width: auto;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 0.9em;
}

.doc-comp i {
  margin-right: 10px;
}
.doc-div {
  display: inline-block;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background: #d5d8db;
  width: 49%;
  margin-right: 5px;
  margin-top: 5px;
}
.doc-div span {
  font-size: 0.8em;
}

/* ---------------------------------------------------
    Add Customers
----------------------------------------------------- */
#quotes-table th:first-child,
#quotes-table td:first-child,
#quotes-table th:last-child,
#quotes-table td:last-child {
  text-align: center;
}
/* ---------------------------------------------------
    PRODUCTION
----------------------------------------------------- */
#view-assemblies-table th:first-child,
#view-assemblies-table td:first-child,
#view-assemblies-table th:last-child,
#view-assemblies-table td:last-child {
  text-align: center;
}
#view-bill-of-material-table td:last-child,
#view-bill-of-material-table th:last-child,
#view-bill-of-material-table th:nth-child(5),
#view-bill-of-material-table th:nth-child(6),
#view-bill-of-material-table th:nth-child(7),
#view-bill-of-material-table td:nth-child(5),
#view-bill-of-material-table td:nth-child(6),
#view-bill-of-material-table td:nth-child(7) {
  text-align: center;
}
.bill-of-material-steps {
  color: #363838;
  font-size: 18px;
  font-weight: 700;
  opacity: 0.8;
  padding: 10px 0 10px 5px;
}

/* ---------------------------------------------------
    ORGANIZATION
----------------------------------------------------- */
#sales-quotes-charge-table_length,
#sales-quotes-charge-table_filter,
#all-back-order-enquiry-table_length,
#all-back-order-enquiry-table_filter {
  display: none;
}

/* ---------------------------------------------------
    ORGANIZATION
----------------------------------------------------- */
.billing-desc {
  text-align: center;
}
.billing-desc .heading {
  font-size: 3em;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 1415px) {
  .available-manus .manu-items .item {
    width: 100%;
  }
  .dropdown-divider {
    width: 97.5%;
    margin-left: 5px;
  }
  #purchase div,
  #sale div,
  #customer div,
  #production div {
    margin-right: -20px !important;
    margin-bottom: 10px;
  }
  .padding-0 {
    margin-left: 15px;
    margin-right: 25px;
  }

  .padding-right {
    margin-right: 15px !important;
  }
  /* #info-card {
		padding: 10px;
	} */
  #doc-checkbox {
    margin-left: 20px;
    margin-top: 2px;
  }
}
.form-inline label {
  margin-right: 12px;
}
@media (max-width: 767px) {
  .role-permissions #heading {
    font-size: 0.7em;
  }
  .role-permissions .permissions-body-text {
    font-size: 0.7em;
    padding-top: 5px;
  }

  .comp-heading .col-sm-6:last-child {
    text-align: center;
  }
  .comp-heading .col-sm-6:first-child {
    text-align: center;
  }
  .dropdown-item {
    padding-left: 20px !important;
  }
  #settings-field .form-control {
    margin-bottom: 10px;
  }
  .css-2b097c-container {
    margin-bottom: 10px;
  }
  .login-desc {
    display: none;
  }
  .registration-page .login-desc {
    border-radius: 0 !important;
    display: none;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }
  .grid-container1 {
    grid-template-columns: 1fr;
  }
  .main-body {
    margin-right: 5px;
  }
  #comp-tab {
    margin-top: 10px;
  }
  .template-forms {
    margin-left: 0;
  }
  #doc-checkbox {
    margin-left: 0;
    margin-top: 2px;
  }

  .manus {
    border-right: none;
  }
  .manu {
    position: initial;
  }
  .quicklinks-dropdown-content {
    left: 5%;
    min-width: 340px;
  }
  .config-container .config-card-body {
    top: 45;
  }
  .form-inline input[type="checkbox"] {
    margin-top: 5px !important;
  }
  .comp-heading {
    text-align: center;
    padding: 10px;
    margin-top: -50px !important;
  }
  .comp-heading .row div:first-child {
    display: none;
  }
  .padding-right {
    margin-right: 15px !important;
  }
  .padding-0 {
    margin-left: 15px;
    margin-right: 25px;
  }
  .padding-1 {
    padding-left: 15px;
  }
  .form-inline input,
  .form-inline select {
    margin-top: -4px !important;
  }
  .form-group {
    margin-bottom: -10px !important;
  }
  #system-add-btn {
    margin-top: 18px;
    margin-left: -5px !important;
  }
  .comp-heading .col-lg-6 {
    padding-top: 5px;
    text-align: center !important;
  }
  #sell-price-tier button {
    float: left;
  }
  .input-label {
    margin-top: 5px;
  }
  #prefix-divs .col-sm-4,
  #inline-input-forms .col-sm-4,
  #inline-input-forms .col-sm-3,
  #inline-input-froms .col-sm-8,
  #inline-input-froms .col-sm-2,
  #inline-input-froms .col-sm-5 {
    text-align: left;
  }
  #prefix-divs .col-sm-6 {
    text-align: left;
  }
  #prefix-divs .form-control,
  #inline-input-forms .form-control {
    width: 100% !important;
  }
  #last-hr {
    margin-right: -20px;
  }
  #second-last-hr {
    margin-right: -20px;
  }

  .show {
    background: none;
  }

  #content {
    width: 102%;
  }

  .notes-textarea textarea {
    width: 100%;
  }
  .product-section-heading {
    margin-left: 5% !important;
  }
  .product-section-heading1 {
    margin-left: 3% !important;
  }
  .table-view {
    width: 98.2% !important;
  }
  .inventory-table-input input,
  .inventory-table-input .form-control {
    width: 70px !important;
  }

  #purchase div,
  #sale div,
  #view-product div,
  #change-code div,
  #customer div,
  #production div {
    margin-bottom: 10px;
    margin-right: 10px !important;
  }
  #purchase .form-control,
  #sale .form-control,
  #view-product .form-control,
  #change-code .form-control,
  #customer .form-control,
  #production .form-control {
    width: 60% !important;
  }
  #add-supplier-btn button,
  #add-customer-btn button,
  #add-production-btn button {
    width: 100px;
    margin-top: 10px !important;
    margin-left: 15% !important;
  }
  .table-view small {
    margin-left: 20%;
  }
  #purchase div,
  #sale div,
  #customer div,
  #production div {
    margin-left: 5% !important;
    margin-right: -100px !important;
  }
  #sellpricetiers .thead-dark {
    width: 100% !important;
  }
  .markup-btn {
    text-align: center !important;
  }
  .comp-heading {
    width: 100%;
    margin-top: -50px;
  }
  .comp-heading label {
    font-size: 20px;
  }

  .register-left {
    margin-top: 0;
  }
  .register-left h2 {
    margin-bottom: -10%;
  }
  .register-right {
    border-radius: 5px !important;
    margin-left: 5%;
    margin-right: -10px;
  }

  #change-code-search-icon {
    display: none;
  }

  #header-btn {
    display: inline;
  }
  #navbarSupportedContent {
    float: none !important;
    margin-top: 50px;
  }

  #details-parent-dev {
    border: none;
  }
  .markup-btn {
    margin-bottom: 0 !important;
  }
  .permissins-heading .permission {
    padding-left: 35px !important;
    width: 50%;
  }
  .permissins-heading .access {
    width: 20%;
  }
  .permissins-heading .can-edit {
    width: 20%;
  }
  .dt-buttons {
    margin-bottom: 5px;
    margin-left: 0;
    text-align: center;
  }
  .billing-desc .heading {
    font-size: 1.8em;
  }
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: white;
  border: 1px solid #c80000;
  border-radius: 13px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
  pointer-events: none;

  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 13px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

#padding-0 .col-lg-1,
#padding-0 .col-lg-10,
#padding-0 .col-lg-11,
#padding-0 .col-lg-12,
#padding-0 .col-lg-2,
#padding-0 .col-lg-3,
#padding-0 .col-lg-4,
#padding-0 .col-lg-5,
#padding-0 .col-lg-6,
#padding-0 .col-lg-7,
#padding-0 .col-lg-8,
#padding-0 .col-lg-9,
#padding-0 .col-md-1,
#padding-0 .col-md-10,
#padding-0 .col-md-11,
#padding-0 .col-md-12,
#padding-0 .col-md-2,
#padding-0 .col-md-3,
#padding-0 .col-md-4,
#padding-0 .col-md-5,
#padding-0 .col-md-6,
#padding-0 .col-md-7,
#padding-0 .col-md-8,
#padding-0 .col-md-9,
#padding-0 .col-sm-1,
#padding-0 .col-sm-10,
#padding-0 .col-sm-11,
#padding-0 .col-sm-12,
#padding-0 .col-sm-2,
#padding-0 .col-sm-3,
#padding-0 .col-sm-4,
#padding-0 .col-sm-5,
#padding-0 .col-sm-6,
#padding-0 .col-sm-7,
#padding-0 .col-sm-8,
#padding-0 .col-sm-9,
#padding-0 .col-xs-1,
#padding-0 .col-xs-10,
#padding-0 .col-xs-11,
#padding-0 .col-xs-12,
#padding-0 .col-xs-2,
#padding-0 .col-xs-3,
#padding-0 .col-xs-4,
#padding-0 .col-xs-5,
#padding-0 .col-xs-6,
#padding-0 .col-xs-7,
#padding-0 .col-xs-8,
#padding-0 .col-xs-9 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#padding-0 > div:first-child {
  padding-right: 10px !important;
}

/***
Bootstrap Line Tabs by @keenthemes
A component of Metronic Theme - #1 Selling Bootstrap 3 Admin Theme in Themeforest: http://j.mp/metronictheme
Licensed under MIT
***/

/* Tabs panel */
.tabbable-panel {
  margin-top: -15px !important;
  margin-left: -15px !important;
  margin-right: -15px !important;
  padding-bottom: 1px;
  border-bottom: 1px solid #eee;
}

/* Default mode */
.tabbable-line > .nav-tabs {
  border: none;
  margin: 0px;
}

.tabbable-line > .nav-tabs > li {
  margin-right: 2px;
}
.tabbable-line > .nav-tabs > li > a {
  transition: all 0.3s;
  border: 0;
  margin-right: 0;
  background-color: #fff;
}
.tabbable-line > .nav-tabs > li > a > i {
  color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li:hover {
  border-bottom: 3px solid #1c4e80;
}

.tabbable-line > .nav-tabs > li.open > a > i,
.tabbable-line > .nav-tabs > li:hover > a > i {
  color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open .dropdown-menu,
.tabbable-line > .nav-tabs > li:hover .dropdown-menu {
  margin-top: 0px;
}
.tabbable-line > .nav-tabs > li {
  border-bottom: 3px solid #fff;
}
.tabbable-line > .nav-tabs > li.active {
  border-bottom: 3px solid #1c4e80;
  position: relative;
}
.tabbable-line > .nav-tabs > li.active > a {
  border: 0;
  color: #333333;
}
.tabbable-line > .nav-tabs > li.active > a > i {
  color: #404040;
}
.tabbable-line > .tab-content {
  margin-top: -3px;
  background-color: #fff;
  border: 0;
  border-top: 1px solid #eee;
  padding: 15px 0;
}
.portlet .tabbable-line > .tab-content {
  padding-bottom: 0;
}

/* Below tabs mode */

.tabbable-line.tabs-below > .nav-tabs > li {
  border-top: 4px solid transparent;
}
.tabbable-line.tabs-below > .nav-tabs > li > a {
  margin-top: 0;
}
.tabbable-line.tabs-below > .nav-tabs > li:hover {
  border-bottom: 0;
  border-top: 4px solid #1c4e80;
}
.tabbable-line.tabs-below > .nav-tabs > li.active {
  margin-bottom: -2px;
  border-bottom: 0;
  border-top: 4px solid #1c4e80;
}
.tabbable-line.tabs-below > .tab-content {
  margin-top: -10px;
  border-top: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}
