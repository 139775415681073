.navbar {
  border-radius: 0;
  border-bottom: 1px solid #ddd;
}
#header-divider {
  border-top: 1px solid #fff !important;
}
.navbar-default {
  background: #ffffff;
}
.app-header {
  border-left: 1px solid #1c4e80 !important;
  border-right: 1px solid #1c4e80 !important;
  z-index: 1000;
  position: fixed;
  border-radius: 0;
  top: -1px;
  padding: 3px;
  width: 100%;
  padding-right: 222px;
  background: #1c4e80 !important;
  transition: 0.3s all;
}
#content.active .app-header {
  padding-right: 7px;
}
#header-nav-Item {
  text-align: center;
  color: #000000;
}
#header-nav-Item .nav-link {
  font-weight: normal;
  font-size: 1.1em !important;
  color: #000000;
}
.customize-quicklinks {
  position: absolute;
  top: 13px;
  left: 70px;
}
.customize-quicklinks i {
  font-size: 2em;
}
.navbar-header,
#navbar-collapse-1 {
  z-index: 3 !important;
}

#header-nav-Item:hover .nav-link {
  background: #1c4e80 !important;
  border-radius: 5px;
}

#header-nav-Item .nav-link:focus {
  background: #1c4e80 !important;
  border-radius: 5px;
}

.show {
  background: #fff;
}

#header-nav-Item:focus {
  background: #1c4e80 !important;
  border-radius: 5px;
}

#header-nav-Item .nav-link {
  color: #fff !important;
}
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #fff !important;
  background-color: #1c4e80 !important;
}
.navbar-default .navbar-nav > li > a {
  color: #fff !important;
}

#header-nav-Item .nav-link[aria-expanded="true"] {
  background: #1c4e80 !important;
  border-radius: 5px;
}
#header-account-icon {
  width: 30px;
  font-size: 1.4em !important;
}
#header-dropdown {
  transition: all 0.3s;
}
#header-dropdown a {
  display: block;
  padding: 7px;
}
#header-dropdown a:hover {
  /* background: #1f2427; */
  background: #fff;
}
.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}
.navbar-toggle {
  background-color: #1c4e80 !important;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #fff !important;
}

.dropdown-divider {
  border-top: 1px solid #e11e39 !important;
}

#sidebarCollapse {
  position: absolute;
  margin-left: 10px;
  width: 35px;
  height: 30px;
  /* display: none; */
  background: #1c4e80 !important;
  cursor: pointer;
}

#sidebarCollapse span {
  height: 2px;
  margin: 0 auto;
  display: block;
  background: #fff !important;
  transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
  transition-delay: 0.2s;
}

#sidebarCollapse span:first-of-type {
  transform: rotate(45deg) translate(2px, 2px);
}
#sidebarCollapse span:nth-of-type(2) {
  opacity: 0;
}
#sidebarCollapse span:last-of-type {
  transform: rotate(-45deg) translate(1px, -1px);
}

#sidebarCollapse.active span {
  transform: none;
  opacity: 1;
  margin: 5px auto;
}
.dropdown-item {
  font-size: 0.8em;
  color: #fff !important;
}
.dropdown-item a:hover {
  color: #000000 !important;
}
.header-icon-name {
  display: none;
  margin-left: 5px;
  margin-right: 5px;
}
@media (max-width: 1415px) {
  .quicklinks-dropdown-content {
    padding: 5px;
  }
  .selected-quicklinks {
    margin-left: -70px;
    width: 380px;
  }
  .selected-quicklinks .selected-quicklinks-content {
    padding: 10px;
  }
  .selected-quicklinks .selected-quicklinks-content .item {
    width: 100%;
  }
}
@media (min-width: 766px) {
  .navbar-default .navbar-nav > li > a {
    margin-right: 5px;
    width: 50px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
@media (max-width: 767px) {
  .navbar-default .navbar-nav > li > a:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .header-icon-name {
    display: inline-block;
  }
  .navbar {
    margin-left: 0;
  }
  .app-header {
    width: 102%;
    padding: 0;
  }
  #header-dropdown {
    background: #fff;
  }
  .dropdown-menu li a,
  .dropdown-menu a span,
  .dropdown-menu a i {
    color: #000000;
  }
  .selected-quicklinks {
    margin-left: -30px;
  }
  #content.active .app-header {
    margin-left: 220px;
  }
  #sidebarCollapse {
    border-radius: 5px;
    display: inline;
  }
  #sidebarCollapse span {
    color: #fff !important;
  }
  #sidebarCollapse span:first-of-type,
  #sidebarCollapse span:nth-of-type(2),
  #sidebarCollapse span:last-of-type {
    transform: none;
    opacity: 1;
    margin: 5px auto;
  }
  #sidebarCollapse.active span {
    margin: 0 auto;
  }
  #sidebarCollapse.active span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px);
  }
  #sidebarCollapse.active span:nth-of-type(2) {
    opacity: 0;
  }
  #sidebarCollapse.active span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px);
  }
}
